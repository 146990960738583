
import { defineComponent, ref } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useOrderListData';
import { loadPage } from '@/utils/publicMethods';

export default defineComponent({
  name: 'UploadLog',
  components: { ErpList },
  props: {},
  setup() {
    const { columnList, tableRef } = useListData();
    const customParams: any = ref();

    // 返回
    const back = () => {
      loadPage('ContractOrderList', {});
    };
    // 查看详情
    const rowClick = () => {
      return true;
    };
    return {
      back,
      customParams,
      columnList,
      tableRef,
      rowClick,
    };
  },
});
