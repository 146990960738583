import { ErpTableOptions } from '@/types/type';

import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      type: 'input',
      value: '',
      minWidth: 140,
    },
    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      type: 'input',
      value: '',
      minWidth: 160,
    },
    {
      label: '资金方',
      prop: 'fundingShortName',
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '抬头',
      prop: 'consigneeShortName',

      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '客户',
      prop: 'customerName',
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '业务类型',
      prop: 'category',
      propDesc: 'categoryDesc',
      minWidth: 128,
      value: '',
      type: 'signSelect',
      options: 'product_category',
    },
    {
      label: '订单状态',
      prop: 'status',
      propDesc: 'statusDisplay',
      type: 'multiSelect',
      value: [],
      options: 'order_status',
      minWidth: 120,
    },
    {
      label: '上游业务合同号',
      prop: 'contractNo',
      type: 'input',
      value: '',
      minWidth: 160,
    },
    {
      label: '签约时间',
      prop: 'signDate',
      filterProp: ['signStartDate', 'signEndDate'],
      minWidth: 120,
      type: 'time',
      value: [],
    },
    {
      label: '合同文件',
      prop: 'fileVoList',
      isFilter: false,
      isSort: false,
      type: 'input',
      value: '',
      minWidth: 100,
      component: defineComponent({
        template: `
        <tableFileContent :readOnly="true"
        :fileUrlsArray="row.attachmentDtos"
        key-name="name"
        key-url="url"
        key-id="attachmentId"
      ></tableFileContent>
        `,
        props: ['row'],
        setup() {
          return {};
        },
      }),
    },
    {
      label: '合同金额(元)',
      prop: 'amountDesc',
      minWidth: 140,
      filterProp: ['amountMin', 'amountMax'],
      type: 'number',
      value: [],
    },
    {
      label: '生效日期',
      prop: 'startDate',
      minWidth: 155,
      type: 'time',
      filterProp: ['startDateStart', 'startDateEnd'],
      value: [],
    },
    {
      label: '到期日期',
      prop: 'endDate',
      minWidth: 155,
      type: 'time',
      filterProp: ['endDateStart', 'endDateEnd'],
      value: [],
    },
    {
      label: '上传人',
      prop: 'lastOperator',
      minWidth: 120,
      type: 'input',
      value: '',
    },
    {
      label: '上传时间',
      prop: 'updateTime',
      minWidth: 155,
      type: 'time',
      filterProp: ['updateStartTime', 'updateEndTime'],
      value: [],
    },
  ];
  return { columnList, tableRef };
};

//
